<template>
    
    <section class="page-banner padding" style="height: 290px;"
          :class="(pgLoading) ? 'webkit-animation webkit-100per-350' : ''" 
          :style="(!pgLoading) ? 'background:url('+background+') !important' : 'background:url()'">
        
        <div class="container" style="background-color: rgba(0, 0, 0, 0.50);width: 100%;padding-top: 30px;">
            <div class="row" >
                <div class="col-md-12 text-center">

                    <h1 v-if="pgLoading" class="text-uppercase webkit-animation webkit-25per-75 mauto mb-5"></h1>
                    <div v-if="!landing">
                        <h1 v-if="!pgLoading" 
                            class="text-uppercase"
                            :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                            {{ currentPage }}
                        </h1>
                    </div>
                    <div v-if="landing">
                        <h1 style="margin-bottom: 20px;" v-if="!pgLoading" 
                            class="text-uppercase"
                            :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                            {{ currentPage }}
                        </h1>
                    </div>
                    <div v-if="!landing">
                        <ol v-if="!pgLoading" class="breadcrumb text-center"
                            :class="($i18n.locale == 'ar') ? 'rtl' : ''">
                            <li>
                                <router-link v-if="$i18n.locale == 'en'"
                                    to="/"
                                    :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                                    {{ $t('header.home') }}
                                </router-link>
                                <router-link v-if="$i18n.locale == 'ar'"
                                        to="/?lang=ar"
                                        :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                                    {{ $t('header.home') }}
                                </router-link>
                            </li>
                            <li v-if="prevPage">
                                <router-link v-if="$i18n.locale == 'en'"
                                             :to="'/'+prevSlug"
                                    :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                                    {{ prevPage }}
                                </router-link>
                                <router-link v-if="$i18n.locale == 'ar'"
                                :to="'/'+prevSlug+'?lang=ar'"
                                        :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                                    {{ prevPage }}
                                </router-link>
                            </li>
                            <li class="active"
                                :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                                {{ currentPage }}
                            </li>
                        </ol>
                    </div>

                </div>
            </div>
        </div>

    </section>

</template>

<script>    
export default {
    name: 'PageBanner',
    props: [
        'pgLoading',
        'landing',
        'background',
        'prevSlug',
        'prevPage',
        'currentPage',
    ],
    data() {
        return {
            //
        }
    }
}
</script>
